import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gy-5 g-xl-8" }
const _hoisted_2 = { class: "col-xxl-12 md-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TotalCount = _resolveComponent("TotalCount")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TotalCount, {
        "widget-classes": "card-xl-stretch mb-xl-8",
        "widget-color": "success",
        "chart-height": "200",
        "stroke-color": "#cb1e46"
      })
    ])
  ]))
}